@import 'src/base';

.persons-fragment{
  .ant-table-cell{
    background: transparent !important;
  }
}
.person-card {
  background-color: $white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .person-columns, .person-card-header{
    flex-flow: column;
  }

  .person-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .ant-space{
      .ant-space-item{
        margin-right: 0px !important;
      }
    }
  }


  .ant-descriptions-row {
    td {
      padding-bottom: 5px;
    }
  }

  .ant-descriptions-title{
    flex: inherit;
  }

  .ant-descriptions-extra{
    margin-left: 10px;
  }

}

@media (min-width: 992px){
  .person-card{
    .person-columns, .person-card-header{
      flex-flow: row wrap;
    }
    .person-tag{
      text-align: right;
    }
  }
}
