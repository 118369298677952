@import 'src/base';

.download-receipts-modal {
  
}
.receip-container{
    margin-top: 10px;
}
.receip-name{
    margin-right: 15px;
    font-weight: 600;
}
a.receip-btn{
    line-height: 1.5em;
}