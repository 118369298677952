@import 'src/base';


.image-camera{
    height: 114px; 
    width: 114px; 
    margin-top: 15px;
}

.icon-add{
    margin-top: -58px; 
    width: 34px; 
    height: 34px; 
    margin-left: 55px;
}

.settings {
    flex: 1;

  .first-container{
    background-color: #FFFFFF;
    text-align: center; 
    padding-top: 25px;
    }  
    
    .second-container{
        margin-top: 25px; 
        padding: 20px;
    }

    .ant-upload.ant-upload-select-picture-card > .ant-upload {
        overflow: hidden;
        border-radius: 8px;
        img{
            background-size: cover;
        }
    }

    .ant-btn{
        margin-top: 60px;
    }
    .cancel-plan-button {
      display: flex;
      margin-top: 0 !important;
    }
}

