@import 'src/base';

.edit-property-services {

  .liabilities-modal-header {
    padding: 0;
  }

  .create-liability-button {
    margin-right: 40px;
  }

  .liability-edit-item {
    align-items: center;
  }

  .liability-title {
    margin-bottom: 0;
  }

  .services-action-buttons {
    margin: 5px;
  }

  .dynamic-input-list {
    .ant-space {
      width: 100%;

      .ant-space-item:first-child {
        width: 100%;

        div {
          width: 100%;
        }
      }

      .ant-space-item:last-child {
        position: relative;

        button {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
}
