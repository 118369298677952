@import 'src/base';

.follow-ups-list {
  flex: 1;

  .flex-loader {
    height: 60vh;
  }
  .ant-dropdown-trigger{
    margin-left: 8px;

    svg{
      font-size: 16px;
      margin-top: 3px;
    }
  }

  .ant-pagination-options{
    display: none;
  }

  .ant-tag{
    padding: 6px 8px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: $white;
    border:none;
    float: right;

    &.ant-tag-green{
      background-color: $success-color;
    }

    &.ant-tag-red{
      background-color: $danger-color;
    }
  }

  .ant-table{
    background: transparent;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 0 10px 0;
  }

  .action-btns{
    background-color: transparent;
    position: relative;
    top: 52px;
    z-index: 2;
    float: right;
    right: 15px;
  }

  .generic-receipt{
    background-color: transparent;
    color: $link-color;
    img{
      margin-right: 0px;
    }

    span{
      color: $link-color;
      display: none;
    }
  }
}
.filter-btn-list{
  span{
    font-size: 90%;
    color: $link-color;
    margin-left: 10px;
  }

}

/* Responsive */

@media (min-width: 992px) {
  .follow-ups-list .generic-receipt span{
    display: inline-block;
    margin-left: 10px;
  }
  .ant-dropdown-trigger{
    svg{
      font-size: 10px;
      margin-top: 0px;
    }
  }
}
