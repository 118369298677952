@import 'src/base';

.contract-fragment {
  flex: 1;

  .add-property-btn{
    margin-bottom: 20px;
  }

  .rescind-btn, .contract-pdf{
    background-color: initial !important;
    padding: 0px !important;
    margin-top: 10px;
  }

  .observations{
    background-color: $background-color;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    width: 100%;
    margin-right: 10px;
    h5{
      font-size: 110%;
      margin-bottom: 0px;
    }
  }
  .contract-header {
    margin-bottom: 40px;
    .text-button{
      color: white;
    }
  }

  .ant-table{
    background: transparent;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 0 10px 0;
  }
}