.stepper-form {
  .ant-steps-item{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .current-step{
  }
  .hidden-step{
    display: none;
  }
  &.ant-layout {
    background-color: transparent;
  }
  .step-navigator {
    margin-top: 20px;
  }
}
