@import 'src/base';

.register {
  margin: 0 auto;
  padding: 0px;
  background-color: $body-color;
  height: 100vh;
  width: 100vw;

  .header{
    width: 100%;
    height: 60px;
    background-color: $primary-color;
    text-align: center;

    .logo{
      margin-top: 10px;
    }
  }

  .auth-form{
    padding: 20px;
    margin: 0 auto;
    margin-top: 40px;
    background-color: $white;
    border-radius: 10px;

    .ant-form-item{
      margin-bottom: 10px;

      &.btn-send{
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .ant-form-item-label{
      display: block;
      width: 100%;
      text-align: left;
    }

    .btn-forgotauth{
      text-align: right;
      width: 100%;
      display: block;
      padding-right: 0px;
    }
  }
}
