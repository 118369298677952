@import 'src/base';

.download-settlements-modal {
    min-width:600px;
}

.settlement-container{
    margin-top: 10px;
}

.settlement-name{
    margin-right: 15px;
    font-weight: 600;
}

.settlement-btn{
    margin: 0 4px;
    line-height: 1.5em;
}

a.settlement-btn{
    line-height: 1.5em;
}

.footer-text p {
    margin-top:10px;
}