@import "src/base";

 .button-logout {
     .text-logout{
         color: rgba(0, 0, 0, 0.45);
         font-size: 14px;
     }

     .text-logout:hover{
         color: $primary-color;
     }
 }
