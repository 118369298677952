@import 'src/base';

.edit-contract {
    .deleteBTN{
        border:none;
        margin-left: -10px;
        svg{
            g{
              g{
                fill: $primary-color
              }
            }
        }
    }
}