@import 'src/base';

.row-wrapper {
  
}
.atp-card{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.follow-up-month-title{
  background-color: transparent !important;
    font-size: 120%;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    padding-top: 15px;
}

/* Responsive */

@media (min-width: 576px) {
  .follow-up-month-title{
    font-size: 120%;
  }
}