@import 'src/base';

.follow-up-card-payment {
  .hidden-not-paid-icon{
    display: none;
  }
  .hidden-is-paid-icon{
    display: none;
  }
  .hidden-partially-paid-icon{
    display: none;
  }

  .not-paid-icon{
    color: red;
  }
  .is-paid-icon{
    color: limegreen;
  }
  .partially-paid-icon{
    color: orange;
  }
  .hidden-upgradeable-follow-up {
    display: none;
  }

  span{
    margin-left: 10px;
    &.anticon-check-circle{
        margin-left: 0;
        font-size: 20px;
    }
    &.anticon-info-circle{
        margin-left:0;
        font-size: 20px;
    }
  }
  .ant-space-align-center{
      align-items: baseline;
      .edit-button{
          padding: 5px;
          svg{
              fill: #498fed;
          }
      }
  }
  .ant-form-item{
      margin-bottom: 0px;
  }

}
