@import 'src/base';

.sider-nav-bar {
  .ant-menu-horizontal{
    text-align: center;
  }
  .hidden-alert{
    //display: none;
  }

  .ant-menu{
    margin-top: 20px;

    svg{
      top: 7px;
      position: relative;
    }
    .ant-menu-item{
      &.ant-menu-item-selected{
        svg{
          g{
            g{
              fill: $primary-color
            }
          }
        }
      }
      &:hover{
        color: $primary-color;
        svg{
          g{
            g{
              fill: $primary-color
            }
          }
        }

        a{
          color: $primary-color;
        }
      }
    }
  }  

  .container-button-logout{
    display: flex;
    justify-content: flex-end;
  }

  .ant-layout-sider-zero-width-trigger{
    background-color: transparent;
    position: absolute;
    top: -54px;

    &:hover::after {
      background: transparent;
  }
  }

  .ant-layout-sider {
    background-color: $white;
    height: 100vh;
    position: fixed;
    left: 0;

    .ant-layout-sider-children{
      box-shadow: 2px 0 4px 0 rgba(0,0,0,0.10);
    }
  }

  .ant-layout-header {
    padding: 0 20px;
  }

  .header-logo {
    height: 35px;
  }

  .sider-header{
    display: flex;
    align-items: baseline;
    width: 100vw !important;
  }

  .avatar-dropdown {
    float: left;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
  }

  .title-header{
    display: flex;
    align-items: center;
    height: 63px;

    .title-screen{
      margin: 0;
      color: white;
      font-size: 120%;
    }
  }

  .avatar{
    display: none;

    .anticon {
      top: 42%;
      position: absolute;
    }

    .button-complete{
      color: white;
      text-decoration: 1px underline white;
    }

    .container-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 5px 5px 5px 10px;

      span, a{
        font-size: 100%;
        line-height: 25px;
        height: initial;
      }

     .name-admin, .name-realEstate{
      color: white;
      line-height: 21px;
     }

     .name-realEstate{
       font-weight: bolder;
     }
     .ant-btn-lg{
      padding: 0;
     }
     .a.ant-btn-lg{
       line-height: 0;
     }
    }
  }

  .sider-content-wrapper {
    margin-top: 65px;
    .ant-menu-item a {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .sider-header {
    position: fixed;
    width: 100%;
    background-color: $primary-color;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  .content-fragments{
    width: 100%;
  }

  .sider-content {
    display: flex;
    justify-content: center;
  }

  .sider-content-full {
    display: flex;
  }

  .content-footer-wrapper {
    flex: 1;
    overflow: auto;
    padding: 15px 15px 40px;
    overflow-x: hidden;
    width: 100vw;
  }

  .sider-footer {
    text-align: center;
  }

  .sider-footer-full {
    text-align: center;
  }

  .text-footer{
    color: #a5a5a5
  }
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right: none;
  right: auto;
  left: 0;
  border-left: 3px solid $primary-color;
}

.ant-menu.BottomNavMenu {
  border-top: 1px solid #eee;
  position: fixed;
  width: 100%;
  bottom: 0;
  svg{
    margin: 0px 5px;
  }
}

/* Responsive */

@media (min-width: 992px) {
  .sider-nav-bar .sider-content, .sider-nav-bar .sider-footer {
    margin-left: 220px;
  }

  .sider-nav-bar .content-footer-wrapper {
    padding: 40px;
  }

  .sider-nav-bar .avatar{
    display: flex;
    align-items: center;
    height: 63px;
  }

  .sider-nav-bar .title-header {
    margin-left: 35px;
  }

  .sider-nav-bar .avatar-dropdown{
    justify-content: flex-start;
  }
}


