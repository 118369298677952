@import 'src/base';

.follow-up-card-taxes {
    img{
        min-width: 30px;
        max-width: 30px;
        margin-top: 5px;
        cursor: pointer;
    }
}


@media (min-width: 992px) {
    .follow-up-card-taxes {
        img{
            min-width: 22px;
            max-width: 22px;
        }
    }
}