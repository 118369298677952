@import 'src/base';

.create-property-service {
    .ant-form-item-label {
        min-width: 120px;
        text-align: left;
    }

    .indeterminate-time-checkbox {
        margin-bottom: 5px;
    }
}
