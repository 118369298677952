@import 'src/base';

.observation-card {
  h5{
    font-size: 100%;
    margin-bottom: 0px;
  }
  .ant-descriptions-item-content{
    span{
      text-transform: capitalize;
    }
  }
  .observation-description {
    text-transform: initial !important;
  }

  .alias-card{
    width: 85%;
    border: none;
  }
  .icon-button {
    width: 15%;
  }

  .ant-card-head-title{
    margin-left: 0;
  }
  
}