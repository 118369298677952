@import 'src/base';

.update-your-plan {
  .update-plan-body{
      font-size: 15px;
  }
  
}
.next-plan, .plan-name{
    display: block;
    text-align: center;
    margin-bottom: 15px;
}
.plan-price, .plan-active-contracts{
    text-align: center;
    display: block;
}
.plan-price{
    margin-top: 10px !important;
}
.plan-active-contracts{
    font-size: 16px;
    margin-top: -10px;
}