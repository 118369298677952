@import 'src/base';

.persons-fragment {
  flex: 1;

  .add-person-btn{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .ant-image{
      margin-right: 10px;
    }
    span{
      margin-top: -3px;
    }
  }

  .ant-tag{
    padding: 6px 8px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.45);
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1;

    &.ant-tag-success{
      border: solid 1px $success-color;
      background-color: rgba(0, 135, 68, 0.1);
      color:$success-color;
    }

    &.ant-tag-red{
      border: solid 1px $danger-color;
      background-color: rgba(209, 36, 64, 0.1);
      color: $danger-color;
    }
  }

  .ant-table-wrapper{
    margin-top: 20px;
  }

  .properties-header {
    margin-bottom: 40px;
  }

  .ant-table{
    background: transparent;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 0 10px 0;
  }
}
