@import 'src/base';

.mail-html{
    .ant-modal-body{
        height: 90%;
        padding: 0px;
    }
    .ant-modal-content{
        height: 500px;
    }

    .ant-modal-footer{
        display: none;
    }
}

@media (min-width: 992px){
    .mail-html{
        .ant-modal-body{
            padding: initial;
        }
        .ant-modal-content{
            height: 800px;
        }
    }
  }