@import 'src/base';

.follow-up-card {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  .follow-up-alias{
    max-width: 170px;
    word-break: break-all;
  }
  .ant-descriptions{
    margin-bottom: 10px;
  }
  .follow-up-card-payment{
    margin-left: -10px;
    margin-bottom: 5px;
  }
  .follow-up-card-taxes{
    a{
      padding: 0px !important;
      line-height: 1.2em;
      height: 24px;
    }
  }
  .follow-up-status-tag{
      position: absolute;
      right: 5px;
      top:20px;
  }
  .ant-descriptions-row {
    td {
      padding-bottom: 5px;
    }
  }
  .ant-descriptions-title{
    flex: inherit;
  }

  .ant-descriptions-extra{
    margin-left: 10px;
  }

}


/* Responsive */

@media (min-width: 768px){
  .follow-up-card{
    .ant-row {
      flex-flow: row wrap;
    }
    .follow-up-alias{
      min-width: 100%;
      max-width: 100%;
      display: inline;
    }
  }
}

@media (min-width: 992px) {

}
