@import 'src/base';

.property-card {
  background-color: $white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .property-card-header{
    margin-bottom: 20px;
  }
  .property-card-taxes{
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .ant-descriptions-header{
      margin-bottom: 5px;
    }
    .ant-descriptions-view{
      margin-bottom: 20px;
    }
  }
  .property-card-details{
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .ant-descriptions-header{
      margin-bottom: 5px;
    }
  }

  .ant-descriptions-row {
    td {
      padding-bottom: 5px;
    }
  }
  .ant-descriptions-title{
    flex: inherit;
  }

  .ant-descriptions-extra{
    margin-left: 10px;
  }

  .long-description {
    .ant-descriptions-item-container {
      display: block;
    }
  }
}

@media (min-width: 992px){
  .property-card {
    .property-card-taxes, .property-card-details{
      border-top: none;
      padding-top: 0px;
    }
  } 
}