@import 'src/base';

.notifications {
    flex: 1;
      
        .ant-table{
          background: transparent;
        }

        .ant-card{
          border-radius: 0px;
        }
        .ant-card-head{
          padding: 0 10px;

          .ant-card-extra{
            width: 52%;
          }
        }
        .ant-card-body{
            padding: 5px;
            
            .ant-typography strong{
              line-height: 1.3em;
              display: block;
            }
            .ch-column{
              display: flex;
              justify-content: center;
            }
        }

        .ant-btn-lg{
            padding: 6.4px 0;
            text-align: left;
        }

        .card-head{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .ant-checkbox-inner{
            border: 0.5px solid black;
            border-radius: 2px;
        }

        .ant-card-head-title{
            font-weight: bold;
        }
      
        .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
          padding: 15px;
        }
        .ant-layout-header{
          background-color: #ededed;
          height: 27px;
          margin-bottom: 20px;
          text-align: center;
          margin-top: 40px;

          h4{
            font-size: 130%;
          }
        }
        .reports div.ant-card:first-of-type{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

@media (min-width: 768px){
  .notifications .ant-card-head .ant-card-extra {
    width: 58%;
    text-align: center;
  }
}

