$primary-color: #7258DB;
$primary-light-color: #d2d1de;
$link-color: #458be9;
$secondary-color: #3c141d;
$background-color: #ededed;
$danger-color: #d12440;
$success-color: #008744;
$black: rgb(38, 38, 38);
$white: #fff;
$body-color: #EDEDED;


#root, #root > div{
    height: 100%;
}

@media (hover: none) {
    .ant-space-item:hover { color: inherit; }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.ant-form-item .ant-input-number-handler-wrap {
    display: none;
}

.follow-ups-list, .persons-fragment, .properties-fragment, .contract-fragment, .notifications{
    max-width: 1300px;
    margin: 0 auto;
}
.layout-settings-fragment-wrapper{
    max-width: 800px;
    margin: 0 auto;
    border-radius: 4px;
}

.ant-layout, .ant-layout-footer{
    background-color: $background-color;
}
.ant-layout-header{
    background-color: $primary-color;

    .ant-menu-item a{
        color: $white;
    }
}
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset;
}

.ant-modal-body{
    .title{
        text-align: left;
        font-size: 140%;
        margin-bottom: 30px;
    }
}
.ant-table{

    .ant-checkbox-inner{
        border-spacing: 0px !important;
    }

    table{
        border-collapse: separate !important;
        border-spacing: 0px 15px !important;
    }

    .ant-descriptions-view{
        table{
            border-collapse: collapse !important;
        }
    }

    .ant-table-content{
        .ant-table-thead{
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            tr{
                th{
                    background-color: $white;

                    &:first-of-type{
                        border-top-left-radius: 4px !important;
                        border-bottom-left-radius: 4px !important;
                    }
                    &:last-of-type{
                        border-top-right-radius: 4px !important;
                        border-bottom-right-radius: 4px !important;
                    }
                    .ant-table-selection{
                        margin-left: 20px;
                    }
                }
            }
        }
        .ant-table-tbody{
            tr{
                td{
                    border-top:1px solid $background-color;
                    border-bottom:1px solid $background-color;
                    background-color: $white;
                    padding: 0px;

                    &:first-of-type{
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    &:last-of-type{
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    &.ant-table-selection-column{
                        vertical-align: initial;
                        padding-top: 20px;
                        padding-left: 20px;
                    }

                    h5.ant-typography, .ant-typography h5{
                        font-weight: 800;
                    }

                    .ant-descriptions{
                        table{
                            tbody{
                                tr{
                                    td{
                                        border:transparent;

                                        .ant-descriptions-item-container{
                                            span{
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ant-row{
                        .ant-col{
                          .ant-btn{
                            padding: 6px;
                            border:none;
                            span{
                              &.anticon-delete{
                                color: $white;
                              }
                            }
                          }
                        }
                    }
                }
            }
        }
    }
}

.ant-form{
    .ant-form-item-label{
        padding: 0px;
        margin-bottom: 0px;
    }
    .ant-form-item{
        margin-bottom: 10px;
        .ant-form-item-label > label{
            font-weight: 600;
            height: 30px;
        }
    }
    .ant-form-item-explain.ant-form-item-explain-error{
        text-align: left;
    }
    .ant-input-number{
        width: 100% !important;
    }
}

.link-icon {
    color: $link-color;
}

.ant-checkbox-inner {
    border-radius: 4px;
}

.ant-steps{
    margin-bottom: 20px;
}
.ant-steps-item-icon{
    line-height: 1.6em;
    border: 3px solid rgba(0, 0, 0, 0.25);
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{
    padding-left: 0px;
    margin-left: -1px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: transparent;
    span{
        color: $primary-color !important;
        font-weight: bold;
    }
}

.ant-steps-item-title{
    margin-top: -10px;

    &::after{
        height: 3px;
        left: -10px;
        top: 27px;
        background: $primary-color !important;
    }
}

.ant-steps-item-active{
    .ant-steps-item-title{
        color: $primary-color !important;
        font-weight: bold;
    }
}

.ant-steps-item-wait{
    .ant-steps-item-container{
        .ant-steps-item-icon{
            span{
                font-weight: bold;
            }
        }
        .ant-steps-item-content{
            &::after{
                position: absolute;
                top: 17px;
                left: 100%;
                display: block;
                width: 9999px;
                height: 1px;
                content: '';
                height: 3px;
                left: 30px;
                background: #dddddd !important;
            }

            .ant-steps-item-title{
                margin-top: -10px;
                font-weight: bold;

            }

        }
    }
}

.ant-steps-item-finish{
    .ant-steps-item-container{
        .ant-steps-item-content{
            .ant-steps-item-title{
                font-weight: bold;
                color: rgba(38, 38, 38, 0.45);
            }
        }
    }
}

.ant-empty{
    padding: 80px 10px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0px;
    border-radius: 4px;
    line-height: 1em;
    color: $black;
    .ant-empty-image{
      display: none;
    }

    a{
      padding: 0px;
    }
  }

  .ant-modal-body {
    max-height: 88vh;
    overflow-y: auto;
}

.ant-col-xs-24{
    .ant-picker{
        width: 100%;
    }
}

.ant-form-item-label{
    label{
        line-height: 1.2em;
    }
}
