@import 'src/base';

.contract-add-observation {
  .dynamic-input-list{
      .ant-space{
          width: 100%;
          .ant-space-item:first-child{
              width: 100%;
              div{
                width: 100%;
              }
          }
          .ant-space-item:last-child{
              position: relative;
              button{
                position: absolute;
                right: 0px;
              }
          }
      }
  }
}