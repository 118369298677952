@import 'src/base';

  .contract-card-details {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;

    .contract-card-header{
      margin-bottom: 20px;
    }
    .contracts-links{
      .hide-rescind-btn{
        display: none;
      }
      .rescind-btn, a{
        height: 24px;
        margin-top: 0px;
        display: block;
      }
    }
    .contract-details, .contract-observations{
      padding-top: 15px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      .ant-descriptions-header{
        margin-bottom: 5px;
      }
      .ant-descriptions-view{
        margin-bottom: 20px;
      }
    }
    

    .ant-descriptions-row {
      td {
        padding-bottom: 5px;
      }
    }
    .ant-descriptions-title{
      flex: inherit;
    }
  
    .ant-descriptions-extra{
      margin-left: 10px;
    }
  
    .long-description {
      .ant-descriptions-item-container {
        display: block;
      }
    }
    .hidden-rescind-button{
        display: none;
    }
    .hidden-observations{
        display: none;
    }
  }

  @media (min-width: 992px){
    .contract-card-details {
      .contract-details, .contract-observations{
        padding-top: 0px;
        border-top: none;
      }
    } 
  }