@import 'src/base';

.properties-fragment {
  flex: 1;

  .add-property-btn{
    margin-bottom: 20px;
  }

  .properties-header {
    margin-bottom: 20px;
    .text-button{
      color: white;
    }
  }

  .ant-table{
    background: transparent;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 0 10px 0;
  }
}
